import React from 'react'

import classes from './dashboard.module.css'
import CustomTable from '../customComponents/customTable/customTable'
import Spinner from '../customComponents/spinner/spinner'
import CustomForm from '../customComponents/customForm/customForm'
import CustomModal from '../customComponents/customModal/customModal'
import ScriptModal from '../customComponents/scriptModal/scriptModal'
import {cacheHeaders,basePath} from '../config'
import M from 'materialize-css'

class Dashboard extends React.Component{

    state={
        currSelectedName: '',
        currSelectedId: 0,
        currSelectedPlace:'',
        tableData:[],
        columns:[
            {
              Header: "ID",
              accessor: "comp_id",
              filter: "text"
            },
            {
              Header: "Name",
              accessor: "comp_name",
              filter: "text"
            },
            {
                Header: "Place ID",
                accessor: "place_id",
                filter: "text"
            },
            {
                Header: "Update Reviews",
                accessor: "review",
                Cell: ({cell}) => {
                    // console.log(cell.row.values.comp_id)
                    return  (<button className={"btn-floating waves-effect waves-light " + classes.ctrlBtn} onClick={()=> this.updateReviews(cell.row.values.comp_id)} >
                                <i className="material-icons">publish</i>
                            </button>)
                }
            },
            {
                Header: "Script",
                accessor: "script",
                Cell: () => {
                    // console.log(cell.row.values.comp_id)
                    return  (<button className={"btn-floating waves-effect waves-light modal-trigger " + classes.ctrlBtn} data-target='breezeview-scripts'>
                                <i className="material-icons">code</i>
                            </button>)
                }
            }
        ],
        formData:{
            comp_id:0,
            comp_name:'',
            place_id:''
        },
        isFormOpen: false,
        isMenuOpen:false,
        formType:'Add',
        isLoading:true,
        hasError:false
    }

    toast = (success,message) =>{
        let toastClasses=['rounded'];
        toastClasses.push(success?classes.ToastSuccess:classes.ToastFailure);
        M.toast({html: message, classes: toastClasses.join(' ')});
    }

    updateReviews = (id) => {
        let reviewsUrl = basePath + '/dashboard/reviews/';
        if(id){
            reviewsUrl = reviewsUrl + id;
        }
        fetch(reviewsUrl,{
            headers: { 
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
             }
        })
        .then(res => {
            return res.ok ? res.json() : Promise.reject(new Error(res.status));        
        })
        .then(data => this.toast(data.success,data.message))
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                this.toast(false,'Authentication Required');
                this.props.history.push('/login');
            }else{
                this.toast(false,'An error occurred and the action could not be completed!');
            }
        })
    }

    formOpenHandler = (open) => {
        this.setState({isFormOpen:open})
    }

    inputChangeHandler= (event) =>{
        let value = event.target.value;
        const formData = {...this.state.formData};
        formData[event.target.name] = value;
        this.setState({formData:formData});
    }

    submitHandler =(event)=>{
        event.preventDefault();
        const postData = this.state.formData;
        fetch(basePath + '/dashboard/',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
                ...cacheHeaders
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            this.toast(data.success,data.message);
            if(data.success){
                this.formOpenHandler(false);
                this.clearFormData();
                this.getCompanyDetails();
            }
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                this.toast(false,'Authentication Required');
                this.props.history.push('/login');
            }else{
                this.toast(false,'An error occurred and the action could not be completed!');
            }
        });      
    }

    clearFormData = () => {
        const initialFormData = {
            comp_id:0,
            comp_name:'',
            place_id:''
        }
        this.setState({
            formData: initialFormData,
            formType: 'Add',
            currSelectedId:0,
            currSelectedName:'',
            currSelectedPlace:''
        })
    }

    rowSelectHandler = (cells) => {
        let id = cells[0].value;
        let name = cells[1].value;
        let place = cells[2].value;

        if(id !== this.state.currSelectedId){
            this.setState({currSelectedId:id,currSelectedName:name,currSelectedPlace:place});
        }
    }

    menuHandler = () => {
        this.setState((prevState) => 
            ({isMenuOpen: !prevState.isMenuOpen})
        )
    }

    addHandler = () => {
        this.clearFormData();
        this.formOpenHandler(true);
        this.menuHandler();
    }

    editHandler = () => {
        let formData = {
            comp_id: this.state.currSelectedId,
            comp_name: this.state.currSelectedName,
            place_id: this.state.currSelectedPlace
        }
        this.setState({formData: formData,isFormOpen:true,isMenuOpen:false,formType:'Edit'});
    }

    deleteHandler = () => {
        fetch(basePath + '/dashboard/'+ this.state.currSelectedId,{
            method: 'delete',
            headers: {
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
            },
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            this.toast(data.success,data.message);
            this.setState({currSelectedId:0,currSelectedName:'',currSelectedPlace:''});
            this.getCompanyDetails();
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                this.toast(false,'Authentication Required');
                this.props.history.push('/login');
            }else{
                this.toast(false,'An error occurred and the action could not be completed!');
            }
        });
    }

    getCompanyDetails = () => {
        this.setState({isLoading:true});
        fetch(basePath + '/dashboard',{
            headers: { 
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
             }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
                this.setState({tableData:data,isLoading:false});
                // For auto completing Company Names
                let autocompleteData={};
                data.forEach(elem => {autocompleteData[elem.comp_name] = null;} );
                const autoElems = document.querySelectorAll('.autocomplete');
                const selectElems = document.querySelectorAll('select');
                M.Autocomplete.init(autoElems, {data:autocompleteData});
                M.FormSelect.init(selectElems);             
            }
        )
        .catch(err => {
            M.AutoInit();
            if(+err.message && +err.message > 400 && +err.message < 500){
                this.toast(false,'Authentication Required');
                this.props.history.push('/login');
            }else{
                this.toast(false,'An Error Occurred');
                this.setState({hasError:true,isLoading:false});
            }
        });
    }

    componentDidMount(){
        M.AutoInit();
        this.getCompanyDetails();
    }

    render(){

        let formClasses=[classes.FormDiv,'card-panel'];
        this.state.isFormOpen && formClasses.push(classes.FormOpen)

        let menuClasses = [classes.ControlBar];
        !this.state.isMenuOpen && menuClasses.push(classes.MenuClosed)

        let content = <Spinner />
        if(!this.state.isLoading)
            content = <CustomTable columns={this.state.columns} data={this.state.tableData} select={this.rowSelectHandler} pagination filter exportable />
        if(this.state.hasError)
            content = <h3>Data Not Available</h3>

        return(
            <div className={classes.DashBoard + ' card-panel'}>
                <div className={classes.TableContainer}>
                    {content}
                </div>
                <div className={classes.ControlPanel}>
                    <div>
                        <button className={"btn-floating btn-large pulse waves-effect cyan darken-3 waves-light"} onClick={this.menuHandler}>
                            <i className="material-icons large">{this.state.isMenuOpen?'close':'menu'}</i>
                        </button>
                    </div>
                    <div className={menuClasses.join(' ')}>
                        <button className={"btn-floating waves-effect waves-light tooltipped "+classes.ctrlBtn} data-tooltip='Add Company' onClick={this.addHandler}>
                        <i className="material-icons">add</i>
                        </button>
                        <button className={"btn-floating waves-effect waves-light tooltipped "+classes.ctrlBtn} data-tooltip='View Report' disabled={this.state.currSelectedId===0} 
                        onClick={() => {this.props.viewReport(this.state.currSelectedId,this.state.currSelectedName);this.props.history.push('/dashboard/report')}} >
                                <i className="material-icons">visibility</i>
                        </button>
                        <button className={"btn-floating waves-effect waves-light tooltipped "+classes.ctrlBtn} data-tooltip='Edit Company' disabled={this.state.currSelectedId===0} onClick={this.editHandler} >
                            <i className="material-icons">edit</i>
                        </button>
                        <button className={"btn-floating waves-effect waves-light tooltipped modal-trigger " + classes.ctrlBtn} onClick={this.menuHandler} data-target='delete_modal' data-tooltip='Delete Company' disabled={this.state.currSelectedId===0} >
                            <i className="material-icons">delete</i>
                        </button>
                        <button className={"btn-floating waves-effect waves-light tooltipped modal-trigger " + classes.ctrlBtn} onClick={this.menuHandler} data-target='update_modal' data-tooltip='Update All Reviews'>
                            <i className="material-icons">system_update_alt</i>
                        </button>
                    </div>
                </div>
                <div className={formClasses.join(' ')}>
                    <div className={classes.FormHeader}>
                        <span>{this.state.formType+' Company'}</span>
                        <i onClick={() => {this.formOpenHandler(false);this.clearFormData()}} className="material-icons">close</i>
                    </div>
                    <CustomForm
                    formData={this.state.formData}
                    change={this.inputChangeHandler}
                    clear={this.clearFormData}
                    save={this.submitHandler} />
                </div>
                <CustomModal modalId='delete_modal' func={this.deleteHandler} title='Delete Entity' content={
                    <p>Are you sure you want to delete this company?<br/>
                        <b>{this.state.currSelectedId + ' - ' + this.state.currSelectedName}</b>
                    </p>
                } />
                <CustomModal modalId='update_modal' func={()=>this.updateReviews(null)} title='Update Reviews' content={
                    <p>Are you sure you want to update the Reviews for all the Companies ?<br/></p>
                } />
                <ScriptModal compId={this.state.currSelectedId} />
            </div>
        )
    }
}

export default Dashboard;