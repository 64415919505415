import React from 'react';
import classes from  './customTable.module.css';
import { useTable, usePagination, useFilters, useGlobalFilter  } from "react-table";
import ExportToCSV from '../ExportToCSV';


// Functions for Filtering data
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter }
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) => {
  const count = preGlobalFilteredRows && preGlobalFilteredRows.length;

  return (
      <>
        <i className="material-icons">search</i>       
        <input
          value={globalFilter || ""}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`${count} records...`}
        />
      </>
  );
};

// Actual Table Component which is exported
const CustomTable = ({ columns, data, select, responsive, pagination, filter, exportable }) => {

  //Filter Types and Default columns
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  ); 

  // Injecting Hooks for using Table with pagination and filter
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state,
      // visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0 }
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );

    // To add conditional responsiveness
    let tableClasses = ['striped', 'highlight', 'centered'];
    if(responsive){
      tableClasses.push('responsive-table');
    }

    return(
    <>
      <div className={classes.ToolContainer}>
        {exportable?<ExportToCSV csvData={data} fileName={'Breezeview-members.xlsx'} />:null}
        {
          filter? <div className={classes.FilterContainer}>
          {/* Added for Filtering */}
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {/* Filter End */}
          </div> : null
        } 
      </div>     
    <div className={classes.CustomTable}>     
      <table {...getTableProps()} className={tableClasses.join(' ')}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {/* Column specific Filter */}
                  {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => {if(select){select({...row.cells})}}} tabIndex='-1' >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    {
      pagination ? <div className={classes.PaginationContainer}>
        <span style={{marginTop:'10px'}}>
          <b>
            Page{" "}
            {state.pageIndex + 1} of {pageOptions.length}            
          </b>
        </span>
        <div style={{margin:'8px'}}>
          <button onClick={() => gotoPage(0)} className={'btn '+ classes.PageBtn} disabled={!canPreviousPage}>
              <i className='material-icons'>first_page</i>
            </button>
            <button onClick={() => previousPage()} className={'btn '+ classes.PageBtn} disabled={!canPreviousPage}>
              <i className='material-icons'>chevron_left</i>
            </button>
            <input
              type="number"
              value={state.pageIndex + 1}
              min='1'
              max={pageCount}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "50px",margin:'10px',textAlign:"center" }}
            />
            <button onClick={() => nextPage()} className={'btn '+ classes.PageBtn} disabled={!canNextPage}>
              <i className='material-icons'>chevron_right</i>
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} className={'btn '+ classes.PageBtn} disabled={!canNextPage}>
              <i className='material-icons'>last_page</i>
            </button>
        </div>       
        <select
          value={state.pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> : null
    }    
      </>
        );
}

export default CustomTable;