import React from 'react';
import {Route,Redirect} from 'react-router-dom';

const ProtectedRoute = ({path,exact,Component, isAuthenticated,...props}) => {
    if(isAuthenticated){
        return <Route path={path} exact={exact} render={(data => <Component {...data} {...props} />)} />
    }
    else {
        return <Redirect to='/login' />
    } 
}

export default ProtectedRoute;