import React from 'react';

import classes from './report.module.css';
import CustomTable from '../customComponents/customTable/customTable';
import Spinner from '../customComponents/spinner/spinner';
import DonutChart from 'react-donut-chart';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'; 
import M from 'materialize-css';
import {cacheHeaders, basePath} from '../config';

class Report extends React.Component{

    state={
        tableData: [{
            clicks:1,
            impressions:1,
            cro:100
        }],
        chartData: [
            {label:'Clicks',value:1},
            {label:'Impressions',value:1}
        ],
        tableColumns: [
            {
              Header: "Clicks",
              accessor: "clicks"
            },
            {
              Header: "Impressions",
              accessor: "impressions"
            },
            {
              Header: "CRO (%)",
              accessor: "cro"
            }
        ],
        reportPeriod:[new Date(),new Date()],
        isLoading:true,
        hasError: false
    }

    getReport= (id,date) => {
        let postData={
            from: date[0].getUTCMonth() + 1,
            to: date[1].getUTCMonth() + 1
        }
        fetch(basePath + '/dashboard/report/'+id,{
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
              ...cacheHeaders
            },
            body: JSON.stringify(postData)
          })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            if(Object.keys(data).length !== 0 ){
                let reportData = [data],chartData=[];
                reportData[0]['cro'] = ((data.clicks/data.impressions)*100).toFixed(2);
                chartData[0] = {label:'clicks',value:data.clicks}
                chartData[1] = {label:'impressions',value:data.impressions}
                this.setState({tableData:reportData,chartData:chartData,isLoading:false});
            } else{
                this.setState({isLoading:false})
            }         
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded '+ classes.ToastFailure});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An Error Occurred', classes: 'rounded '+ classes.ToastFailure});
                this.setState({hasError:true});
            }
        });
    }

    dateHandler = (reportRange) =>{
        this.setState({reportPeriod:reportRange});
        this.getReport(this.props.compId,reportRange);
    }

    componentDidMount(){
        this.getReport(this.props.compId,this.state.reportPeriod);
    }

    render(){
        
        let content = <Spinner />
        if(!this.state.isLoading)
            content = <CustomTable columns={this.state.tableColumns} data={this.state.tableData} responsive />
        if(this.state.hasError || this.state.tableData.length === 0)                               
            content = <h3>Data Not Available</h3>

        return (
            <>        
                <div className={classes.ReportControl}>
                    <button className={'waves-effect waves-light btn '+ classes.BackBtn} onClick={()=>this.props.history.push('/dashboard')} >
                        <i className="material-icons left">arrow_back</i>
                        Go Back
                    </button>
                    <h4>{this.props.compName}</h4>
                    <DateRangePicker
                        className={'card-panel ' + classes.DateRangePicker}
                        onChange={this.dateHandler}
                        value={this.state.reportPeriod}
                    />
                </div>
                <div className={'card-panel ' + classes.ReportTable}>
                    {content}
                    <div className={classes.ReportChart}>
                        <DonutChart 
                         data={this.state.chartData}
                         width={700}
                         innerRadius={0.6}
                         colors={['#f44336', '#e91e63', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ]}
                         />
                    </div>                    
                </div>
            </>
    );
    }
}


export default Report;