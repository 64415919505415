import React from 'react';
import classes from './customForm.module.css'

const CustomForm = ({formData,change,clear,save}) => {
    return(
        <form onSubmit={save}>
            <div className="row">
                <div className="input-field col s12">
                    <input id="comp_name" name='comp_name' type="text" value={formData.comp_name} onChange={change} required autoComplete='off' className="validate autocomplete" />
                    <label className={formData.comp_name !== '' ? 'active' : ''} htmlFor="comp_name">Company Name</label>
                </div>
                <div className="input-field col s12">
                    <input id="place_id" name="place_id" value={formData.place_id} onChange={change} type="text" required className="validate" />
                    <label className={formData.place_id !== '' ? 'active' : ''} htmlFor="place_id">Place ID</label>
                </div>
                <div className="input-field col s12">
                    <button className={"btn waves-effect waves-light "+classes.FormBtn} type="submit" name="save">Save
                        <i className="material-icons right">cloud_upload</i>
                    </button>
                    <button className={"btn waves-effect waves-light "+classes.FormBtn} onClick={clear} type="button" name="clear">Clear
                        <i className="material-icons right">clear_all</i>
                    </button>
                </div>               
            </div>
        </form>
    );
}

export default CustomForm;