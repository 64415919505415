import React from 'react';
import './customModal.css'

const CustomModal = (props) => {
    return (
        <div id={props.modalId} className="modal CustomModal">
            <div className="modal-content">
            <h4>{props.title}</h4>
                {props.content}
            </div>
            <div className="modal-footer">
                <button className="btn modal-close waves-effect waves-green" onClick={props.func}>Yes</button>
                <button className="btn modal-close waves-effect waves-red">No</button>
            </div>
        </div>
    )
}

export default CustomModal;