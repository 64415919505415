import React from 'react';
import './scriptModal.css'

const ScriptModal = (props) => {
    return (
        <div id='breezeview-scripts' className="modal ScriptModal">
            <div className="modal-content">
            <h4>Breezeview Scripts</h4>
                <p id='scriptContent'>                   
                    {"<link href='https://breezeview.breezesuite.com/breezeview-scripts/static/css/breezeview.css' rel='stylesheet'>"}<br/>
                    {"<script>localStorage.setItem('compId',"+ props.compId +")</script>"}<br/>
                    {"<script src='https://breezeview.breezesuite.com/breezeview-scripts/static/js/breezeview-run.js'></script>"} <br/>
                    {"<script src='https://breezeview.breezesuite.com/breezeview-scripts/static/js/breezeview-main.js'></script>"} <br/>
                    {"<script src='https://breezeview.breezesuite.com/breezeview-scripts/static/js/breezeview-chunk.js'></script>"}
                </p>
            </div>
            <div className="modal-footer">
                <button className="btn modal-close waves-effect">Ok</button>
            </div>
        </div>
    )
}

export default ScriptModal;
